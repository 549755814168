import React from "react"
// import { Link } from "gatsby"

import indexImage from "../images/index-image-new-smaller.jpg"

import Layout from "../components/layout"
// import ImageSpaceMan from "../components/image"
import SEO from "../components/seo"

import { Container, Image, Row, Col } from 'react-bootstrap';

const IndexPage = () => (
  <Layout>
    <SEO title="Kindness Through The Art of Medicine" />
    <div className="masthead mb-5">
      <Container>
        <Row className="flex-row-reverse">
          <Col lg={6} className="order-md-1">
            <h1>Kindness Through The Art of Medicine</h1>
            <hr />
            <p>Stay tuned as we are launching the podcast soon!</p>
          </Col>
          <Col lg={6} className="order-md-2">
            <div className="indexBoxShadowPadding">
              <Image src={indexImage} alt="child at doctor's office" className="indexBoxShadow" fluid />
            </div>
          </Col>
          
        </Row>
      </Container>
      
    </div>
    <Container className="singleCol introText">
      <p>Welcome to Dr. Humankind. This is a blog and podcast about how I go through life as a Doctor caring about the human species.  As a pediatrician that works in a hospital, I take you through some of the experiences I have as I see and treat complex patients. I give you an idea of how doctors think and how we see the world, so differently than most. </p>
      <p>When I meet you, I see your stress, fears and frustrations. I understand how challenging it can be to maneuver through the health care system.  I want you to know that there are Doctors everywhere like me that care about you and want the best for you and your family.</p>
      <p>As I go through life juggling medicine and family, loving the challenge and excitement about being a doctor while at times growing weary and worn of the changing nature of the job, I hope you develop a new understanding of doctors like me that practice medicine with kindness.</p>
      <p>I see the beauty of humankind. I am here to show you how medicine is a true art in the way we diagnose, and, in the way, we treat patients and their families.</p>
      <p>I hope my blog will touch you with my experiences as I go through life trying to improve humankind, one touch at a time.</p>
      <p>Yours truly,<br />
      Dr. Humankind</p>
      {/* <ImageSpaceMan /> */}
    </Container>
  </Layout>
)

export default IndexPage
